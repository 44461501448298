import React from 'react';
import { Button, Tooltip } from "@chakra-ui/react";
import { LabeledInput } from './baseComponents/LabeledInput';
import { CenteredBox } from './registrationBaseComponent/CenteredBox';
import { RegistrationBody } from './RegistrationBody';
import { RegistrationFooter } from './registrationBaseComponent/RegistrationFooter';

import { InputContainer } from './InputContainer';
import { LabeledSelect } from './baseComponents/LabeledSelect';

import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';
import { ValidateFromButton } from './baseComponents/ValidateFromButton';
import { RegistrationHeader } from './registrationBaseComponent/RegistrationHeader';
import { RegistrationForm } from '../types/FormData';
import { asyncAll } from '../helpers/async';
interface RegistrationBillingInformationProps {
    nextStep: () => void;
    previousStep: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    registrationForm: RegistrationForm;
}

export const RegistrationBillingInformation: React.FC<RegistrationBillingInformationProps> = (props: RegistrationBillingInformationProps) => {

    const validateAddress = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("address", "Address is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateCity = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("city", "City is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateZipCode = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("zipCode", "Zip Code is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateCountry = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("country", "Country is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateState = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("state", "State is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateCanadianProvence = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("canadaProvence", "Canada Provence is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateExpirationDate = () => {
        const cardsExpiration = props.registrationForm.data.cardsExpiration;
        if (!cardsExpiration) {
            props.registrationForm.updateError("cardsExpiration", "Expiration is required.");
            return false;
        }
        const expirationRegex = /(0[1-9]|1[012])\/2\d{3}/; // 01 - 12 & any year in 2000s
        if (!cardsExpiration?.match(expirationRegex)) {
            props.registrationForm.updateError("cardsExpiration", "Expiration must follow the mm/yyyy format.");
            return false;
        }
        props.registrationForm.updateError("cardsExpiration", "");
        return true;
    };

    const validateSecurityNumber = () => {
        const cardsSecurityNumber = props.registrationForm.data.cardsSecurityNumber;
        if (!cardsSecurityNumber) {
            props.registrationForm.updateError("cardsSecurityNumber", "Card's Security Code is required.");
            return false;
        }
        const expirationRegex = /[0-9]{3,4}/;
        if (!cardsSecurityNumber?.match(expirationRegex)) {
            props.registrationForm.updateError("cardsSecurityNumber", "Invalid Card's Security Code.");
            return false;
        }
        props.registrationForm.updateError("cardsSecurityNumber", "");
        return true;
    };

    const validateCreditCardNumber = () => {
        const creditCardNumber = props.registrationForm.data.creditCardNumber;
        if (!creditCardNumber) {
            props.registrationForm.updateError("creditCardNumber", "Card's Number is required.");
            return false;
        }
        const cardsType = getCreditCardType(creditCardNumber);
        if (!cardsType) {
            props.registrationForm.updateError("creditCardNumber", "Invalid Card's Number.");
            return false;
        }
        props.registrationForm.updateError("creditCardNumber", "");
        return true;
    };

    const validateNameOnCard = () => {
        const nameOnCard = props.registrationForm.data.nameOnCard;
        if (!nameOnCard) {
            props.registrationForm.updateError("nameOnCard", "Name on Card is required.");
            return false;
        }
        if (!nameOnCard.includes(" ")) {
            props.registrationForm.updateError("nameOnCard", "Invalid name on card.");
            return false;
        }
        props.registrationForm.updateError("nameOnCard", "");
        return true;
    };

    const getCreditCardType = (cardsNumber: string) => {
        // visa
        var re = new RegExp("^4");
        if (cardsNumber.match(re) != null)
            return "Visa";

        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(cardsNumber))
            return "Mastercard";

        re = new RegExp("^3[47]");
        if (cardsNumber.match(re) != null)
            return "AMEX";

        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (cardsNumber.match(re) != null)
            return "Discover";

        re = new RegExp("^36");
        if (cardsNumber.match(re) != null)
            return "Diners";

        re = new RegExp("^30[0-5]");
        if (cardsNumber.match(re) != null)
            return "Diners - Carte Blanche";

        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (cardsNumber.match(re) != null)
            return "JCB";

        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (cardsNumber.match(re) != null)
            return "Visa Electron";

        return "";
    };

    const creditCardNamePlaceholded = "Add a New Card";
    const hasOtherCards = Object.keys(props.registrationForm.data.creditCards).length !== 0;

    const isAgencyAccount = props.registrationForm.data.isAgencyAccount;
    const isCardSelected = props.registrationForm.data.creditCardName;
    const displayAddressInputOnly = isAgencyAccount || isCardSelected;

    const validateAll = async () => {
        const validationFunctions = [
            validateAddress,
            validateCity,
            validateZipCode,
            validateCountry,
        ];
        if (!isCardSelected && !isAgencyAccount) {
            validationFunctions.push(validateExpirationDate, validateSecurityNumber, validateCreditCardNumber, validateNameOnCard,);
        }
        let isFormValid = await asyncAll(validationFunctions);

        if (props.registrationForm.data.country === "USA" || !props.registrationForm.data.country) {
            return validateState() && isFormValid;
        }
        return validateCanadianProvence() && isFormValid;
    };

    return (
        <div>
            <CenteredBox>
                <RegistrationHeader text={`${isAgencyAccount ? "10DLC Information" : "Billing Information"}`} />
                <RegistrationBody>
                    {
                        hasOtherCards ?
                            <InputContainer>
                                <LabeledSelect value={props.registrationForm.data.creditCardName} name="creditCardName" flexBasis="100%" onChange={props.registrationForm.updateData} placeholder={creditCardNamePlaceholded} labelText="Select a Card:" options={props.registrationForm.data.creditCards} />
                            </InputContainer> : null
                    }
                    {
                        displayAddressInputOnly ?
                            null
                            :
                            <>
                                <InputContainer>
                                    <LabeledInput name="nameOnCard" flexGrow={10} placeholder="Name on Card" labelText="Name on Card" onChange={props.registrationForm.updateData} value={props.registrationForm.data.nameOnCard} errorText={props.registrationForm.errors.nameOnCard} onBlur={validateNameOnCard} />
                                    <LabeledInput name="cardsExpiration" flexGrow={1} flexBasis="70px" placeholder="mm/yyyy" labelText="Expiration" onChange={props.registrationForm.updateData} value={props.registrationForm.data.cardsExpiration} errorText={props.registrationForm.errors.cardsExpiration} onBlur={validateExpirationDate} />
                                </InputContainer>
                                <InputContainer>
                                    <LabeledInput
                                        name="creditCardNumber"
                                        flexGrow={10}
                                        placeholder="Credit Card Number"
                                        labelText="Credit Card Number"
                                        type="tel"
                                        inputMode="numeric"
                                        pattern="[0-9\s]{13,19}"
                                        autoComplete="cc-number"
                                        maxLength={19}
                                        onChange={props.registrationForm.updateData}
                                        value={props.registrationForm.data.creditCardNumber}
                                        errorText={props.registrationForm.errors.creditCardNumber}
                                        onBlur={validateCreditCardNumber}
                                    />
                                    <LabeledInput
                                        name="cardsSecurityNumber"
                                        flexGrow={1}
                                        flexBasis="60px"
                                        placeholder="123"
                                        onChange={props.registrationForm.updateData}
                                        labelText={
                                            [
                                                "CVV ",
                                                <Tooltip key="cvv" label="Card's Security Code" fontSize="md">
                                                    <InfoCircle size="12px" />
                                                </Tooltip>
                                            ]
                                        }
                                        value={props.registrationForm.data.cardsSecurityNumber}
                                        errorText={props.registrationForm.errors.cardsSecurityNumber}
                                        onBlur={validateSecurityNumber}
                                    />
                                </InputContainer>
                            </>
                    }
                    <InputContainer>
                        <LabeledInput name="address" placeholder="Address" labelText={isCardSelected ? "Address (Needed for 10DLC Registration)" : "Address"} onChange={props.registrationForm.updateData} onBlur={validateAddress} value={props.registrationForm.data.address} errorText={props.registrationForm.errors.address} />
                    </InputContainer>
                    <InputContainer>
                        <LabeledSelect value={props.registrationForm.data.country} name="country" flexBasis="24%" onChange={props.registrationForm.updateData} onBlur={validateCountry} placeholder={"--"} labelText="Country" options={countriesObject} errorText={props.registrationForm.errors.country} />
                        {props.registrationForm.data.country === "USA" || !props.registrationForm.data.country ?
                            <LabeledSelect value={props.registrationForm.data.state} name="state" flexBasis="15%" onChange={props.registrationForm.updateData} onBlur={validateState} placeholder={"--"} labelText="State" options={allUsStatesObject} errorText={props.registrationForm.errors.state} /> :
                            <LabeledSelect value={props.registrationForm.data.canadaProvence} name="canadaProvence" flexBasis="15%" onChange={props.registrationForm.updateData} onBlur={validateCanadianProvence} placeholder={"--"} labelText="Provence" options={allCanadaStatesObject} errorText={props.registrationForm.errors.canadaProvence} />
                        }
                        <LabeledInput name="city" flexBasis="32%" placeholder="City" labelText="City" onChange={props.registrationForm.updateData} onBlur={validateCity} value={props.registrationForm.data.city} errorText={props.registrationForm.errors.city} />
                        <LabeledInput name="zipCode" flexBasis="20%" placeholder="Zip Code" labelText="Zip Code" onChange={props.registrationForm.updateData} onBlur={validateZipCode} value={props.registrationForm.data.zipCode} errorText={props.registrationForm.errors.zipCode} />
                    </InputContainer>

                </RegistrationBody >
                <RegistrationFooter>
                    <Button onClick={props.previousStep} colorScheme="blue" height="36px" width="150px">Previous</Button>
                    <ValidateFromButton registrationForm={props.registrationForm} validateFormData={validateAll} onClick={props.nextStep} isDisabled={props.registrationForm.isLoading} >{props.registrationForm.data.platformName === "ActiveCampaign" ? "Next" : "Submit"} </ValidateFromButton>
                </RegistrationFooter>
            </CenteredBox >
        </div >
    );
};

const countriesObject = { "United States": "USA", "Canada": "Canada" };
const allUsStatesObject = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'American Samoa': 'AS',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Marshall Islands': 'MH',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Palau': 'PW',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virgin Islands': 'VI',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

const allCanadaStatesObject = {
    "Alberta": "AB",
    "British Columbia": "BC",
    "Manitoba": "MB",
    "New Brunswick": "NB",
    "Newfoundland and Labrador": "NL",
    "Northwest Territories": "NT",
    "Nova Scotia": "NS",
    "Nunavut": "NU",
    "Ontario": "ON",
    "PrinceEdward Island": "PE",
    "Quebec": "QC",
    "Saskatchewan": "SK",
    "Yukon": "YT",
};