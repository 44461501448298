import { useState } from "react";
type StringMap<T> = {
    [P in keyof T]: string
};
export type ValueOf<T> = T[keyof T];

export const useForm = <FormDataType extends {}>(initialData: FormDataType) => {
    const [data, setData] = useState(initialData);
    const initialErroKeyArray = Object.keys(initialData).map((key) => ({ [key]: "" }));
    const initialError = Object.assign({}, ...initialErroKeyArray); // set all errors to an empty string 
    const [errors, setErrors] = useState(initialError as StringMap<FormDataType>);
    const [isLoading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const updateData = (newFormData: Partial<FormDataType>) => {
        setData((previousData) => { return { ...previousData, ...newFormData }; });
    };

    const updateError = (name: keyof FormDataType, value: string) => {
        setErrors((previousErrors) => { return { ...previousErrors, [name]: value }; });
    };

    const validateNotEmpty = (dataKey: keyof FormDataType, errorText: string) => {
        if (!data[dataKey]) {
            updateError(dataKey, errorText);
            return false;
        }
        updateError(dataKey, "");
        return true;
    };

    return { data, updateData, errors, updateError, validateNotEmpty, isLoading, setLoading, setSubmitError, submitError };
};
