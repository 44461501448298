import React from 'react';
import { Heading } from "@chakra-ui/react";

interface RegistrationHeaderProps {
    text: string;
}

const registrationHeaderStyle = {
    borderTopLeftRadius: ".375rem",
    borderTopRightRadius: ".375rem",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "#f9fafd"
} as React.CSSProperties;

export const RegistrationHeader: React.FC<RegistrationHeaderProps> = ({ text }) => {
    return (<Heading size="md" style={registrationHeaderStyle}>
        {text}
    </Heading>);
};