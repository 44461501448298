import React from 'react';

interface FlexTextProps {
    fontSize?: string;
    children: React.ReactNode;
}

export const FlexText: React.FC<FlexTextProps> = (props: FlexTextProps) => {
    const textStyle = {
        alignSelf: "flex-start",
        color: "#696969",
        letterSpacing: ".02em",
        fontWeight: 500,
        fontSize: props.fontSize ?? ".725em",
        display: "flex",
    } as React.CSSProperties;

    return (<div style={textStyle}>
        {props.children}
    </div>);
};