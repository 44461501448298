import React from 'react';

interface ErrorTextProps {
    isVisible: boolean;
    fontSize?: string;
    children: React.ReactNode;
}

export const ErrorText: React.FC<ErrorTextProps> = (props: ErrorTextProps) => {
    const errorStyle = {
        alignSelf: "flex-start",
        color: "#b80f0a",
        letterSpacing: ".02em",
        fontWeight: 500,
        fontSize: props.fontSize ?? ".625em",
        transform: "scaleY(1)",
        opacity: 1,
        maxHeight: "101%",
        transition: "opacity 1s linear, all .5s ease-in-out"
    } as React.CSSProperties;

    const hideStyle = {
        alignSelf: "flex-start",
        color: "#b80f0a",
        letterSpacing: ".02em",
        fontWeight: 500,
        fontSize: props.fontSize ?? ".625em",
        transform: "scaleY(0)",
        opacity: 0,
        maxHeight: 0,
        transition: "all .3s ease-in-out"
    } as React.CSSProperties;

    return (<div style={props.isVisible ? errorStyle : hideStyle}>
        {props.children}
    </div>);
};