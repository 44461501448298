import React, { Children } from 'react';

interface RegistrationFooterProps {

}


export const RegistrationFooter: React.FC<RegistrationFooterProps> = ({ children }) => {
    const justifyContent = Children.count(children) === 1 ? "flex-end" : "space-between";
    const registrationFooterStyle = {
        display: "flex",
        justifyContent: `${justifyContent}`,
        gap: "40px",
        paddingTop: "15px",
        paddingBottom: "15px",
        backgroundColor: "#f9fafd",
        paddingLeft: "64px",
        paddingRight: "64px",
        borderBottomLeftRadius: ".375rem",
        borderBottomRightRadius: ".375rem"
    } as React.CSSProperties;
    return (<div style={registrationFooterStyle}>
        {children}
    </div>);
};