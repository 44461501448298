import React, { useState } from 'react';
import activecampaignLogo from '../assets/images/Active Campaign.png';
import hubspotLogo from '../assets/images/hubspot_small.svg';
import dripLogo from '../assets/images/drip-logo_small.png';
import { SelectBox } from './baseComponents/SelectBox';
import { Text } from "@chakra-ui/react";
import { ErrorText } from './baseComponents/ErrorText';
import { RegistrationFormData, RegistrationFormDataValue } from '../types/FormData';
interface SelectPlatformProps {
    onChange: (updatedFormData: Partial<RegistrationFormData>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    platformName: string | undefined;
    errorText: string | undefined;
}

const platformText = {
    alignSelf: "flex-start",
    color: "#5e6e82",
    letterSpacing: ".02em",
    fontWeight: 500,
    textAlign: "left",
    paddingBottom: "0px"
} as React.CSSProperties;

const platformWrapperStyle = {
    display: "flex",
    paddingBottom: "5px",
    gap: "5px",
    alignItems: "center"
} as React.CSSProperties;

const platformNames = ["ActiveCampaign", "Hubspot", "Drip"];

export const SelectPlatform: React.FC<SelectPlatformProps> = (props) => {
    const [platform, setPlatform] = useState(props.platformName ?? "");
    const selectPlatform = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        for (const platformName of platformNames) {
            const platformElement = document.getElementById(platformName);
            if (platformElement) {
                platformElement.style.border = "";
                platformElement.style.padding = "";
            }
        }
        if (platformNames.includes(event.currentTarget.id)) {
            setPlatform(event.currentTarget.id);
            props.onChange({ "platformName": event.currentTarget.id });
        }
        event.currentTarget.style.border = "solid 1px #D3D3D3";
        event.currentTarget.style.padding = "10px";
    };

    return (
        <>
            <Text
                fontSize=".83333rem"
                style={platformText} > Pick Your Platform:</Text>
            <input style={{ transform: "scale(.005)" }} type="checkbox" />
            <div style={platformWrapperStyle}>
                <SelectBox selected={platform === "ActiveCampaign"} id="ActiveCampaign" onClick={(e) => selectPlatform(e)}>
                    <div style={{ padding: "10px", paddingTop: "4px" }}>
                        <img src={activecampaignLogo} alt="Active Campaign logo" />
                    </div>
                </SelectBox>
                <SelectBox selected={platform === "Hubspot"} id="Hubspot" onClick={(e) => selectPlatform(e)}>
                    <img src={hubspotLogo} style={{ paddingLeft: "5px" }} alt="Hubspot logo" />
                </SelectBox>
                <SelectBox selected={platform === "Drip"} id="Drip" onClick={(e) => selectPlatform(e)}>
                    <div style={{ padding: "25px", paddingTop: "28px" }}>
                        <img src={dripLogo} alt="Drip logo" />
                    </div>
                </SelectBox>
            </div>
            <ErrorText isVisible={Boolean(props.errorText)}>{props.errorText}</ErrorText>
        </>
    );
};