import './App.css';
import { SignUpFlow } from './components/SignUpFlow';


function App() {
  return (
    <div className="App">
      <SignUpFlow />
    </div>
  );
}

export default App;
