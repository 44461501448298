import React, { ChangeEvent, MouseEvent } from 'react';
import { Select } from "@chakra-ui/react";
import { ErrorText } from './ErrorText';
import { ValueOf } from '../../hooks/useForm';
import { Label } from './Label';

interface LabeledSelectProps<FormDataType> {
    name: keyof FormDataType;
    labelText?: string;
    placeholder?: string;
    value?: string;
    type?: string;
    flexGrow?: number;
    width?: string;
    flexBasis?: string;
    options?: Options;
    errorText?: string;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    validate?: (event: React.FocusEvent<HTMLSelectElement>) => { isValid: boolean, errorText?: string; };
    onChange?: (updatedFormData: Partial<FormDataType>) => void;
}

interface Options {
    [key: string]: string | undefined;
}



export const LabeledSelect = <T,>(props: LabeledSelectProps<T>) => {

    const renderOptions = (options: Options) => {
        const elementArray: JSX.Element[] = [];

        for (const [key, value] of Object.entries(options)) {
            elementArray.push(<option key={key} value={value ? value : key}>{key}</option>);
        }
        return elementArray;
    };

    const defaultProps = {
        placeholder: "",
        labelText: "",
        type: "text",
        flexGrow: 0,
        width: "100%",
        flexBasis: "auto",
        options: {}
    };
    props = { ...defaultProps, ...props };

    const hangleOnBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
        if (props.onBlur) props.onBlur(event);
    };

    const handleChange = (e: ChangeEvent<HTMLSelectElement>, options: Options) => {
        const selectedKey = e.target.selectedOptions[0].text as keyof T;
        const selectedValue = options[selectedKey as unknown as keyof Options];
        e.target.selectedOptions[0].text = selectedValue ? selectedValue : selectedKey as string;
        if (props.onChange) props.onChange({ [props.name]: selectedValue } as Partial<T>);
    };

    const handleSelect = (e: MouseEvent<HTMLSelectElement, globalThis.MouseEvent>, options: Options) => {
        const selectedValue = e.currentTarget.selectedOptions[0].text;
        const selectedKey = Object.keys(options).find(key => options[key] === selectedValue);
        if (selectedKey) {
            e.currentTarget.selectedOptions[0].text = selectedKey;
        }
    };

    const inputWrapperStyle = {
        flexGrow: props.flexGrow,
        flexBasis: props.flexBasis,
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        paddingBottom: "0px",
        boxSizing: "border-box",
        WebkitFontSmoothing: "antialiased",
        fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol"
    } as React.CSSProperties;

    return (
        <div style={inputWrapperStyle}>
            <Label>{props.labelText}</Label>
            <Select name={props.name as string} color="#5e6e82" onMouseDown={(e: MouseEvent<HTMLSelectElement, globalThis.MouseEvent>) => handleSelect(e, props.options ?? {})} onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e, props.options ?? {})} onBlur={hangleOnBlur} width={props.width} height="36px" value={props.value} placeholder={props.placeholder}>
                {renderOptions(props.options ?? {})}
            </Select>
            <ErrorText isVisible={Boolean(props.errorText)}>{props.errorText}</ErrorText>
        </div>
    );
};



