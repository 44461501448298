import { Button } from '@chakra-ui/react';
import React from 'react';
import { Spinner3 } from '@styled-icons/evil/Spinner3';
import styled, { keyframes } from 'styled-components';
import { RegistrationForm } from '../../types/FormData';
interface ValidateFromButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  registrationForm: RegistrationForm;
  validateFormData: () => Promise<boolean>;
}
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Spinner3)`
  animation: 1.5s linear ${spin} infinite;
`;
export const ValidateFromButton: React.FC<ValidateFromButtonProps> = (props: ValidateFromButtonProps) => {

  const handleClick = async function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const form = event.currentTarget.form;
    if (!form) {
      return;
    }
    const isValid = await props.validateFormData();
    if (isValid && props.onClick) props.onClick();
  };

  return (<Button isLoading={props.registrationForm.isLoading} isDisabled={props.isDisabled} onClick={e => handleClick(e)} colorScheme="blue" height="36px" width="150px">{props.isDisabled ? <>Processing <Spinner size="1.5em" /></> : props.children}</Button>);
};