import React, { useState } from 'react';
import { Input } from "@chakra-ui/react";
import { ErrorText } from './ErrorText';

import { ValueOf } from '../../hooks/useForm';
import { Label } from './Label';
interface LabeledInputProps<FormDataType> {
    name: keyof FormDataType;
    value?: string;
    labelText?: string | (string | any)[];
    placeholder?: string;
    type?: string;
    flexGrow?: number;
    width?: string;
    flexBasis?: string;
    inputMode?: React.HTMLAttributes<HTMLLIElement>['inputMode'];
    pattern?: string;
    autoComplete?: string;
    maxLength?: number;
    errorText?: string;
    errorVisible?: boolean;
    onChange?: (updatedFormData: Partial<FormDataType>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
}

export const LabeledInput = <T,>(props: LabeledInputProps<T>) => {

    const defaultProps = {
        labelText: "",
        type: "text",
        flexGrow: 1,
        width: "100%",
        flexBasis: "auto",
        autoComplete: "",
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange?.({ [props.name]: event.target.value } as unknown as Partial<T>);
    };

    props = { ...defaultProps, ...props };

    const hangleOnBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) props.onBlur(event);
    };
    const inputWrapperStyle = {
        flexGrow: props.flexGrow,
        flexBasis: props.flexBasis,
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        paddingBottom: "0px",
        boxSizing: "border-box",
        WebkitFontSmoothing: "antialiased",
        fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol"
    } as React.CSSProperties;

    return (
        <div style={inputWrapperStyle}>
            <Label>{props.labelText}</Label>
            <Input
                name={props.name as string}
                width={props.width}
                value={props.value}
                height="36px"
                type={props.type}
                placeholder={props.placeholder}
                inputMode={props.inputMode}
                pattern={props.pattern}
                autoComplete={props.autoComplete}
                maxLength={props.maxLength}
                onChange={(e) => handleChange(e)}
                onBlur={async (e) => await hangleOnBlur(e)}
            />
            {props.children}
            <ErrorText isVisible={Boolean(props.errorText)}>{props.errorText}</ErrorText>
        </div >
    );
};


