import React from 'react';
import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { CenteredBox } from './registrationBaseComponent/CenteredBox';
import { RegistrationBody } from './RegistrationBody';
import { RegistrationFooter } from './registrationBaseComponent/RegistrationFooter';
import { InputContainer } from './InputContainer';
import { RegistrationHeader } from './registrationBaseComponent/RegistrationHeader';
import { FlexText } from './baseComponents/FlexText';
import { RegistrationForm } from '../types/FormData';
interface RegistrationPaymentPlanProps {
    nextStep: () => void;
    previousStep: () => void;
    onChange: (nextValue: string) => void;
    registrationForm: RegistrationForm;
}


export const RegistrationPaymentPlan: React.FC<RegistrationPaymentPlanProps> = (props: RegistrationPaymentPlanProps) => {
    const handleChange = (nextValue: string) => {
        props.onChange(nextValue);
    };

    const autoCompleteNameOnCreditCard = () => {
        if (!props.registrationForm.data.nameOnCard) {
            props.registrationForm.updateData({ 'nameOnCard': `${props.registrationForm.data.firstName} ${props.registrationForm.data.lastName}` });
        }
    }
    const isAgencyAccount = props.registrationForm.data.isAgencyAccount;
    return (
        <div>
            <CenteredBox>
                <RegistrationHeader text="Choose Payment Option" />
                <RegistrationBody>
                    <InputContainer>
                        <RadioGroup width={"100%"} onChange={handleChange} value={props.registrationForm.data.pricingPlanId}>
                            <Stack direction="column">
                                { // starter only available for non agency accounts
                                    isAgencyAccount ? null :
                                        <>
                                            <Radio value="16">YetiText Starter Plan: $0/month</Radio>
                                            <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-start" }}>
                                                <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                                    <FlexText>1 Free Phone Number</FlexText>
                                                    <FlexText>$5/mo Additional Numbers</FlexText>
                                                    <FlexText>$.06 per SMS</FlexText>
                                                    <FlexText>$.08 per MMS</FlexText>
                                                </div>
                                                <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                                    <FlexText>$.05 per Outbound Phone Minute</FlexText>
                                                    <FlexText>$.05 per Inbound Phone Minute</FlexText>
                                                    <FlexText>$.10 per Direct Voicemail</FlexText>
                                                    <FlexText>1 Team Members Included</FlexText>
                                                </div>
                                            </div>
                                        </>
                                }
                                <Radio value="14">YetiText Standard Plan: $100/month</Radio>
                                <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-start" }}>
                                    <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                        <FlexText>$20 SMS Credit w/ Trial</FlexText>
                                        <FlexText>1000 Monthly SMS Credit</FlexText>
                                        <FlexText>1 Free Phone Number</FlexText>
                                        <FlexText>$5/mo Additional Numbers</FlexText>
                                        <FlexText>$.03 per SMS</FlexText>
                                        <FlexText>$.05 per MMS</FlexText>
                                    </div>
                                    <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                        <FlexText>$.03 per Outbound Phone Minute</FlexText>
                                        <FlexText>$.04 per Inbound Phone Minute</FlexText>
                                        <FlexText>$.10 per Direct Voicemail</FlexText>
                                        <FlexText>5 Team Members Included</FlexText>
                                        <FlexText>$10/Mo Additional User</FlexText>
                                    </div>
                                </div>
                                <Radio value="15">YetiText Premier Plan: $300/month</Radio>
                                <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-start" }}>
                                    <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                        <FlexText>10000 Monthly SMS Credit</FlexText>
                                        <FlexText>2 Free Local Phone Numbers</FlexText>
                                        <FlexText>1 Free High-Speed Number included</FlexText>
                                        <FlexText>(<b>600 Messages per minute send rate</b>)</FlexText>
                                        <FlexText>$5/mo per Additional Number</FlexText>
                                    </div>
                                    <div style={{ flexGrow: 2, alignContent: "flex-start", display: "flex", flexDirection: "column" }}>
                                        <FlexText>$.03 per SMS</FlexText>
                                        <FlexText>$.04 per MMS</FlexText>
                                        <FlexText>$.02 per Outbound Phone Minute</FlexText>
                                        <FlexText>$.04 per Inbound Phone Minute</FlexText>
                                        <FlexText>$.08 per Direct Voicemail</FlexText>
                                        <FlexText>Unlimited Team Members</FlexText>
                                    </div>
                                </div>
                            </Stack>
                        </RadioGroup>
                    </InputContainer>
                </RegistrationBody >
                <RegistrationFooter>
                    <Button onClick={props.previousStep} colorScheme="blue" height="36px" width="150px">Previous</Button>
                    <Button onClick={() => { autoCompleteNameOnCreditCard(); props.nextStep(); }} colorScheme="blue" height="36px" width="150px">Next</Button>
                </RegistrationFooter>
            </CenteredBox >
        </div >
    );
};
