import { Button } from '@chakra-ui/react';
import React from 'react';
import { asyncAll } from '../helpers/async';
import { RegistrationForm } from '../types/FormData';
import { LabeledInput } from './baseComponents/LabeledInput';
import { ValidateFromButton } from './baseComponents/ValidateFromButton';
import { InputContainer } from './InputContainer';
import { CenteredBox } from './registrationBaseComponent/CenteredBox';
import { RegistrationFooter } from './registrationBaseComponent/RegistrationFooter';
import { RegistrationHeader } from './registrationBaseComponent/RegistrationHeader';
import { RegistrationBody } from './RegistrationBody';


interface RegistrationActiveCampaignProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    nextStep: () => void;
    previousStep: () => void;
    registrationForm: RegistrationForm;
}

export const RegistrationActiveCampaign: React.FC<RegistrationActiveCampaignProps> = (props: RegistrationActiveCampaignProps) => {

    const redirectToActiveCampaign = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("activeCampaignUsername", "Input Active Campaign's Account Name before proceeding.");
        if (isEmpty) return;
        const activeCampaignAccountUrl = "https://" + props.registrationForm.data.activeCampaignUsername + ".activehosted.com/app/settings/developer";
        window.open(activeCampaignAccountUrl, '_blank');
    };

    const validateActiveCampaignAccountName = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("activeCampaignUsername", "Active Campaign's Account Name is required.");
        if (isEmpty) return false;
        props.registrationForm.updateError("activeCampaignUsername", "");
        return true;
    };

    const validateActiveCampaignApiKey = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("activeCampaignApiKey", "Active Campaign's Api Key is required.");
        if (isEmpty) return false;
        props.registrationForm.updateError("activeCampaignApiKey", "");
        return true;
    };

    const validateAll = async () => {
        const validationFunctions = [
            validateActiveCampaignAccountName,
            validateActiveCampaignApiKey
        ];
        const isFormValid = await asyncAll(validationFunctions);
        return isFormValid;
    };

    return (<div>
        <CenteredBox>
            <RegistrationHeader text="Active Campaign Integration" />
            <RegistrationBody>
                <InputContainer>
                    <span style={{ textAlign: "left" }}>
                        Provide <b>Active Campaign's Account Name</b> and click the <b>Get Api Key</b> button which will direct you to Active Campaign's website.
                    </span>
                    <LabeledInput name="activeCampaignUsername" placeholder="Active Campaign's Account Name" labelText="Active Campaign's Account Name" onChange={props.registrationForm.updateData} onBlur={validateActiveCampaignAccountName} value={props.registrationForm.data.activeCampaignUsername} errorText={props.registrationForm.errors.activeCampaignUsername}  >
                        <Button colorScheme="blue" onClick={(e) => redirectToActiveCampaign()}>Get Api Key</Button>
                    </LabeledInput>
                </InputContainer>
                <InputContainer>
                    <span style={{ textAlign: "left" }}>
                        Active Campaign should direct you to to the <b>Api Key</b>. It can also be located manually by navigating to <b>Settings &#62; Developer</b> on the Active Campain's page.
                    </span>
                    <LabeledInput name="activeCampaignApiKey" onBlur={validateActiveCampaignApiKey} placeholder="Active Campaign's API Key" labelText="Active Campaign's API Key" onChange={props.registrationForm.updateData} value={props.registrationForm.data.activeCampaignApiKey} errorText={props.registrationForm.errors.activeCampaignApiKey} />
                </InputContainer>
            </RegistrationBody>
            <RegistrationFooter>
                <Button onClick={props.previousStep} colorScheme="blue" height="36px" width="150px">Previous</Button>
                <ValidateFromButton registrationForm={props.registrationForm} validateFormData={validateAll} onClick={props.nextStep} isDisabled={props.registrationForm.isLoading} > Submit </ValidateFromButton>
            </RegistrationFooter>
        </CenteredBox>
    </div >);
};


