import React from 'react';

interface RegistrationBodyProps {

}
const registrationBodyStyle = {
    paddingTop: "20px",
    display: "flex",
    minHeight: "450px",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "64px",
    paddingRight: "64px",
    justifyContent: "center"
} as React.CSSProperties;

export const RegistrationBody: React.FC<RegistrationBodyProps> = ({ children }) => {
    return (<div style={registrationBodyStyle}>
        {children}
    </div>);
};