import React, { useState } from 'react';
import { Button, Divider, Tooltip } from "@chakra-ui/react";
import { LabeledInput } from './baseComponents/LabeledInput';
import { CenteredBox } from './registrationBaseComponent/CenteredBox';
import { RegistrationBody } from './RegistrationBody';
import { RegistrationFooter } from './registrationBaseComponent/RegistrationFooter';
import { InputContainer } from './InputContainer';
import { SelectPlatform } from './SelectPlatform';
import { ValidateFromButton } from './baseComponents/ValidateFromButton';
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox';
import { RegistrationHeader } from './registrationBaseComponent/RegistrationHeader';
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { Message } from "@styled-icons/boxicons-regular/";
import { ArrowToRight } from "@styled-icons/boxicons-regular/ArrowToRight";
import { Redo } from "@styled-icons/evil/";
import { asyncAll } from '../helpers/async';
import { RegistrationForm, RegistrationFormData, ValueOf } from '../types/FormData';
interface RegistrationBasicInfoProps {
    nextStep: () => void;
    registrationForm: RegistrationForm;
}

const phoneUtil = PhoneNumberUtil.getInstance();

export const RegistrationBasicInfo: React.FC<RegistrationBasicInfoProps> = (props: RegistrationBasicInfoProps) => {

    const [verificationSent, setVerificationSent] = useState(false);
    const [verificationButtonLoading, setVerificationButtonLoading] = useState(false);

    const sendVerification = async () => {
        setVerificationButtonLoading(true);
        const isValid = await validatePhoneNumber();
        if (isValid) {
            const unformattedPhoneNumber = props.registrationForm.data.phoneNumber;
            const e164ContactPhoneNumberObject = phoneUtil.parseAndKeepRawInput(unformattedPhoneNumber, 'US');
            const e164ContactPhoneNumber = phoneUtil.format(e164ContactPhoneNumberObject, PhoneNumberFormat.E164);
            const verificationData = {
                contactPhoneNumber: e164ContactPhoneNumber
            };
            const requestOptions = {
                method: "POST",
                mode: "cors" as RequestMode,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(verificationData)
            };
            try {
                const response = await fetch(process.env.REACT_APP_SEND_PHONE_NUMBER_VERIFICATION_CODE_ENDPOINT ?? "", requestOptions);
                setVerificationButtonLoading(false);
                if (response.status === 200) {
                    props.registrationForm.updateError("phoneNumber", "");
                    setVerificationSent(true); // show retry instead
                    return;
                }
                const text = await response.text();
                if (text) {
                    props.registrationForm.updateError("phoneNumber", text);
                }
            } catch (error) {
                props.registrationForm.updateError("phoneNumber", "Failed to send. Check internet connection.");
                console.error(error);
            }
        }
        setVerificationButtonLoading(false);
    };

    const validateCompanyName = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("companyName", "Company Name is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateFirstName = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("firstName", "First Name is required.");
        if (isEmpty) return false;
        return true;
    };

    const validateLastName = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("lastName", "Last Name is required.");
        if (isEmpty) return false;
        return true;
    };

    const validatePasswordMatching = () => {
        if (!props.registrationForm.data.passwordConfirmation) {
            props.registrationForm.updateError("passwordConfirmation", "Password Confirmation is required.");
            return false;
        }
        if (props.registrationForm.data.password !== props.registrationForm.data.passwordConfirmation) {
            props.registrationForm.updateError("passwordConfirmation", "Provided passwords do not match.");
            return false;
        }
        props.registrationForm.updateError("passwordConfirmation", "");
        return true;
    };

    const validatePassword = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("password", "Password is required.");
        if (isEmpty) return false;
        const MINIMUM_PASSOWRD_LENGTH = 6;
        if (props.registrationForm.data.password?.length < MINIMUM_PASSOWRD_LENGTH) {
            props.registrationForm.updateError("password", "Password needs to be at least 6 characters long.");
            return false;
        }
        props.registrationForm.updateError("password", "");
        return true;
    };

    const validateEmail = async () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("email", "Email is required.");
        if (isEmpty) return false;
        const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        const email = props.registrationForm.data.email;
        if (!email?.match(EMAIL_REGEX)) {
            const errorText = email ? "Invalid email provided." : "Email is required.";
            props.registrationForm.updateError("email", errorText);
            return false;
        }
        props.registrationForm.updateError("email", "");
        return true;
    };

    const validatePhoneNumber = async () => {
        const unformattedPhoneNumber = props.registrationForm.data.phoneNumber;
        const isEmpty = !props.registrationForm.validateNotEmpty("phoneNumber", "Mobile Phone is required.");
        if (isEmpty) return false;
        try {
            const e164ContactPhoneNumberObject = phoneUtil.parseAndKeepRawInput(unformattedPhoneNumber, 'US');
            const isValidNumber = phoneUtil.isPossibleNumber(e164ContactPhoneNumberObject) && (phoneUtil.isValidNumberForRegion(e164ContactPhoneNumberObject, "US") || phoneUtil.isValidNumberForRegion(e164ContactPhoneNumberObject, "CA"));
            if (!isValidNumber) {
                props.registrationForm.updateError("phoneNumber", "Invalid US phone number provided.");
                return false;
            }
        } catch (error) {
            props.registrationForm.updateError("phoneNumber", "Invalid phone number provided.");
            return false;
        }
        props.registrationForm.updateError("phoneNumber", "");
        return true;
    };

    const phoneNumberOnChange = (updatedFormData: Partial<RegistrationFormData>) => {
        const newFormData = {
            "lastPhoneNumberVerificationCode": "",
            "phoneNumberVerificationCode": "",
            ...updatedFormData
        }

        props.registrationForm.updateData(newFormData);
        props.registrationForm.updateError("phoneNumberVerificationCode", "");
    };

    const validateVerificationCode = async () => {
        const phoneNumberVerificationCode = props.registrationForm.data.phoneNumberVerificationCode;
        const lastPhoneNumberVerificationCode = props.registrationForm.data.lastPhoneNumberVerificationCode;
        if (phoneNumberVerificationCode.length < 6) {
            props.registrationForm.updateError("phoneNumberVerificationCode", "Invalid verification code.");
            return false;
        }
        const alreadyTried = phoneNumberVerificationCode === lastPhoneNumberVerificationCode;
        if (props.registrationForm.data["isPhoneVerified"] && alreadyTried) {
            return true;
        }
        if (!props.registrationForm.data["isPhoneVerified"] && alreadyTried) {
            props.registrationForm.updateError("phoneNumberVerificationCode", "Invalid verification code.");
            return false;
        }
        props.registrationForm.updateData({ "lastPhoneNumberVerificationCode": phoneNumberVerificationCode });
        const contactPhoneNumber = props.registrationForm.data.phoneNumber;
        const verificationData = {
            contactPhoneNumber,
            phoneNumberVerificationCode
        };
        const requestOptions = {
            method: "POST",
            mode: "cors" as RequestMode,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(verificationData)
        };
        try {
            const response = await fetch(process.env.REACT_APP_PHONE_NUMBER_VERIFICATION_ENDPOINT ?? "", requestOptions);
            if (response.status === 200) {
                props.registrationForm.updateError("phoneNumberVerificationCode", "");
                props.registrationForm.updateData({ "isPhoneVerified": true });
                return true;
            }
            const text = await response.text();
            if (text) {
                props.registrationForm.updateError("phoneNumberVerificationCode", "Invalid verification code.");
                props.registrationForm.updateData({ "isPhoneVerified": false });
                return false;
            }
        } catch (error) {
            console.error(error);
        }
        props.registrationForm.updateError("phoneNumberVerificationCode", "Verification request failed.");
        return false;
    };

    const validatePlatformName = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("platformName", "Please select a platform.");
        if (isEmpty) return false;
        props.registrationForm.updateError("platformName", "");
        return true;
    };

    const validateAcceptedConditions = () => {
        const isEmpty = !props.registrationForm.validateNotEmpty("acceptedConditions", "Please accept terms and conditions to proceed.");
        if (isEmpty) return false;
        props.registrationForm.updateError("acceptedConditions", "");
        return true;
    };

    const sessionUuid = props.registrationForm.data.sessionUuid;
    const keapContactId = props.registrationForm.data.contactId;

    const validateFormData = async () => {
        const validationFunctions = [
            validatePhoneNumber,
            validateVerificationCode,
            validatePlatformName,
            validateAcceptedConditions
        ];

        if (!sessionUuid) {
            validationFunctions.push(validateEmail, validatePassword, validatePasswordMatching, validateCompanyName, validateFirstName, validateLastName);
        } else if (!keapContactId) {
            validationFunctions.push(validateEmail, validateCompanyName, validateFirstName, validateLastName);
        }

        const isFormValid = await asyncAll(validationFunctions);
        if (isFormValid) {
            await createKeapContactRequest();
        }
        return isFormValid;
    };

    const createKeapContactRequest = async () => {
        if (keapContactId && !isNaN(Number(keapContactId)) && Number(keapContactId) > 0) {
            return;
        }
        const firstName = props.registrationForm.data.firstName;
        const lastName = props.registrationForm.data.lastName;
        const unformattedPhoneNumber = props.registrationForm.data.phoneNumber;
        const email = props.registrationForm.data.email;
        const companyName = props.registrationForm.data.companyName;
        const verificationData = {
            contactPhoneNumber: unformattedPhoneNumber,
            email,
            firstName,
            lastName,
            companyName
        };
        const requestOptions = {
            method: "POST",
            mode: "cors" as RequestMode,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(verificationData)
        };
        try {
            await fetch(process.env.REACT_APP_CREATE_CONTACT_ENDPOINT ?? "", requestOptions);
        } catch (error) {
            props.registrationForm.updateError("phoneNumber", "Failed to send. Check internet connection.");
            console.error(error);
        }
    };

    return (
        <div>
            <CenteredBox>
                <RegistrationHeader text={`${sessionUuid ? "Linked Account" : ""} Registration`.trim()} />
                <RegistrationBody>
                    {!keapContactId &&
                        <>
                            <InputContainer>
                                <LabeledInput name="firstName" placeholder="First Name" labelText="First Name" onChange={props.registrationForm.updateData} value={props.registrationForm.data.firstName} errorText={props.registrationForm.errors.firstName} onBlur={validateFirstName} />
                                <LabeledInput name="lastName" placeholder="Last Name" labelText="Last Name" onChange={props.registrationForm.updateData} value={props.registrationForm.data.lastName} errorText={props.registrationForm.errors.lastName} onBlur={validateLastName} />
                            </InputContainer>
                            <InputContainer>
                                <LabeledInput name="companyName" placeholder="Company's Name" labelText="Company Name" onChange={props.registrationForm.updateData} value={props.registrationForm.data.companyName} errorText={props.registrationForm.errors.companyName} onBlur={validateCompanyName} />
                            </InputContainer>
                            <InputContainer>
                                <LabeledInput name="email" placeholder="Email" labelText="Email" onChange={props.registrationForm.updateData} onBlur={validateEmail} value={props.registrationForm.data.email} errorText={props.registrationForm.errors.email} />
                            </InputContainer>
                        </>
                    }
                    <InputContainer>
                        <LabeledInput name="phoneNumber" placeholder="Mobile Phone" labelText="Mobile Phone" onChange={phoneNumberOnChange} value={props.registrationForm.data.phoneNumber} errorText={props.registrationForm.errors.phoneNumber} onBlur={validatePhoneNumber} />
                        <div style={{ paddingTop: "29px" }}>
                            {verificationButtonLoading ? <Button height="34px" padding="0px" isLoading /> :
                                <Button onClick={sendVerification} height="34px" padding="0px" colorScheme="blue">
                                    {verificationSent ?
                                        <Tooltip label="Resend Verification Code"><Redo size="20px" /></Tooltip> :
                                        <><Tooltip label="Send Verification Code"><ArrowToRight size="12px" /></Tooltip>
                                            <Tooltip label="Send Verification Code"><Message size="12px" /></Tooltip></>
                                    }
                                </Button>
                            }
                        </div>
                        <LabeledInput name="phoneNumberVerificationCode" placeholder="999999" labelText="Verification Code" onChange={props.registrationForm.updateData} value={props.registrationForm.data.phoneNumberVerificationCode} errorText={props.registrationForm.errors.phoneNumberVerificationCode} onBlur={validateVerificationCode} />
                    </InputContainer>
                    {!sessionUuid &&
                        <InputContainer>
                            <LabeledInput name="password" placeholder="Password" labelText="Password" type="password" onChange={props.registrationForm.updateData} value={props.registrationForm.data.password} errorText={props.registrationForm.errors.password} onBlur={validatePassword} />
                            <LabeledInput name="passwordConfirmation" placeholder="Confirm Password" labelText="Confirm Password" type="password" onChange={props.registrationForm.updateData} value={props.registrationForm.data.passwordConfirmation} errorText={props.registrationForm.errors.passwordConfirmation} onBlur={validatePasswordMatching} />
                        </InputContainer>
                    }
                    <SelectPlatform onChange={props.registrationForm.updateData} platformName={props.registrationForm.data?.platformName} errorText={props.registrationForm.errors.platformName} />
                    <Divider />
                    <TermsAndConditionsCheckbox onChange={props.registrationForm.updateData} defaultIsChecked={Boolean(props.registrationForm.data?.acceptedConditions)} errorText={props.registrationForm.errors.acceptedConditions} />
                </RegistrationBody >
                <RegistrationFooter>
                    <ValidateFromButton registrationForm={props.registrationForm} validateFormData={validateFormData} onClick={props.nextStep} >Next</ValidateFromButton>
                </RegistrationFooter>
            </CenteredBox >
        </div >
    );
};


