import React from 'react';
import { createUseStyles } from 'react-jss';

interface SelectBoxProps {
    onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>, ...args: any[]): void;
    id?: string;
    selected: boolean;
    children: React.ReactNode;
}




export const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
    const useStyles = createUseStyles({
        selectBoxStyle: {
            border: props.selected ? "solid 1px #D3D3D3" : "",
            padding: props.selected ? "10px" : "",
            borderRadius: ".375rem",
            overflow: "hidden",
            flexBasis: "33%",
            height: "100px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            '&:hover': {
                padding: "10px"
            }
        }
    });

    const classes = useStyles();

    return (
        <div onClick={props.onClick} id={props.id} className={classes.selectBoxStyle} >
            {props.children}
        </div >);
};