import React, { useEffect, useState } from "react";
import { ErrorText } from "./baseComponents/ErrorText";
import { RegistrationActiveCampaign } from './RegistrationActiveCampaign';
import { RegistrationBasicInfo } from './RegistrationBasicInfo';
import { RegistrationBillingInformation } from './RegistrationBillingInformation';
import { RegistrationPaymentPlan } from "./RegistrationPaymentPlan";
import { RegistrationFormData } from "../types/FormData";
import { useForm } from "../hooks/useForm";
interface SignUpFlowProps { }

type Step = "basicInfo" | "paymentPlan" | "billing" | "platform";

export const SignUpFlow: React.FC<SignUpFlowProps> = () => {
    useEffect(() => {
        window.onbeforeunload = () => {
            return true;
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    const sessionUuid = queryParams.get("session_uuid") ?? "";
    const referrerCode = queryParams.get("referrer-code") ?? "";

    const initialFormData = {
        contactId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        platformName: "",
        passwordConfirmation: "",
        companyName: "",
        nameOnCard: "",
        cardsExpiration: "",
        creditCardNumber: "",
        cardsSecurityNumber: "",
        address: "",
        country: "",
        state: "",
        canadaProvence: "",
        city: "",
        zipCode: "",
        activeCampaignUsername: "",
        activeCampaignApiKey: "",
        pricingPlanId: "16",
        phoneNumberVerificationCode: "",
        lastPhoneNumberVerificationCode: "",
        isPhoneVerified: false,
        acceptedConditions: false,
        creditCards: {},
        creditCardName: "",
        sessionUuid: sessionUuid,
        isAgencyAccount: false,
        referrerCode
    } as RegistrationFormData;

    const [steps, setSteps] = useState(["basicInfo", "paymentPlan", "billing", "platform"] as Step[]);
    const registrationForm = useForm(initialFormData);
    const loadSession = async () => {
        if (sessionUuid) {
            registrationForm.setLoading(true);
            const requestOptions = {
                method: "GET",
                mode: "cors" as RequestMode,
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_REGISTRATION_SESSION_ENDPOINT}/${sessionUuid}`, requestOptions);
                if (response.status === 200) {
                    const data = await response.json();
                    const newFormData = {
                        "companyName": data.companyName,
                        "creditCards": data.creditCards,
                        "pricingPlanId": String(data.pricingPlanId),
                        "isAgencyAccount": data.isAgencyAccount,
                        "contactId": data.infusionsoftContactId ?? ""
                    }
                    registrationForm.updateData(newFormData)
                    if (data.isAgencyAccount) {
                        registrationForm.updateData({ "pricingPlanId": "14" });
                    }
                    const abridgedSteps: Step[] = ["basicInfo"];
                    if (!data.pricingPlanId) abridgedSteps.push("paymentPlan");
                    abridgedSteps.push("billing", "platform");
                    setSteps(abridgedSteps);
                    registrationForm.setLoading(false);
                    return;
                }
                const errorText = await response.text();
                registrationForm.setSubmitError(errorText);
            } catch (error) {
                console.error(error);
            }
            registrationForm.setLoading(false);
        }
    };
    useEffect(() => {
        loadSession();
    }, []);

    const [stepIndex, setStep] = useState(0);

    interface SubmitData extends Omit<RegistrationFormData, "pricingPlanId" | "acceptedConditions" | "passwordConfirmation"> {
        pricingPlanId: number;
    }

    const nextStep = async () => {
        if (registrationForm.isLoading) return;
        if (stepIndex + 1 === steps.length || (registrationForm.data.platformName !== "ActiveCampaign" && steps[stepIndex + 1] === "platform")) {
            registrationForm.setLoading(true);
            const submitData = {
                ...registrationForm.data,
                pricingPlanId: Number(registrationForm.data.pricingPlanId),
                acceptedConditions: undefined,
                passwordConfirmation: undefined
            } as SubmitData; // clone registrationForm.data in case form is invalid
            const requestOptions = {
                method: "POST",
                mode: "cors" as RequestMode,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(submitData) // stringify will strip undefined values
            };
            try {
                const response = await fetch(process.env.REACT_APP_REGISTRATION_ENDPOINT ?? "", requestOptions);
                if (response.status === 200) {
                    const data = await response.json();
                    window.onbeforeunload = null;
                    window.location.href = data.redirectUrl;
                    return;
                }
                response.text().then(text => {
                    registrationForm.setSubmitError(text);
                });
            } catch (error) {
                console.error(error);
            }
            registrationForm.setLoading(false);
            return;
        };
        setStep((previousStepIndex) => previousStepIndex + 1);
    };

    const previousStep = () => {
        if (registrationForm.isLoading) return;
        setStep(stepIndex - 1);
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name as keyof RegistrationFormData;
        const value = event.target.value;
        registrationForm.updateData({ [name]: value });
    };

    const handlePaymentPlanChange = (nextValue: string) => {
        registrationForm.updateData({ "pricingPlanId": nextValue });
    };

    return (
        <form>
            <div style=
                {{ height: "75px" }}
            ></div>
            <ErrorText fontSize=".8em" isVisible={registrationForm.submitError.length !== 0}> {registrationForm.submitError} </ErrorText>
            {
                steps[stepIndex] === "basicInfo" ?
                    <RegistrationBasicInfo registrationForm={registrationForm} nextStep={nextStep} /> :
                    null
            }
            {
                steps[stepIndex] === "paymentPlan" ?
                    <RegistrationPaymentPlan registrationForm={registrationForm} nextStep={nextStep} previousStep={previousStep} onChange={handlePaymentPlanChange} /> :
                    null
            }
            {
                steps[stepIndex] === "billing" ?
                    <RegistrationBillingInformation registrationForm={registrationForm} nextStep={nextStep} previousStep={previousStep} onChange={handleFormChange} /> :
                    null
            }
            {
                steps[stepIndex] === "platform" ?
                    <RegistrationActiveCampaign registrationForm={registrationForm} previousStep={previousStep} nextStep={nextStep} onChange={handleFormChange} /> :
                    null
            }
        </form >
    );
};