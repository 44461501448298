
import { Text } from "@chakra-ui/react";
interface LabelProps {
    children: React.ReactNode;
}

export const Label = (props: LabelProps) => {
    const textStyle = {
        alignSelf: "flex-start",
        color: "#5e6e82",
        letterSpacing: ".02em",
        fontWeight: 500
    } as React.CSSProperties;

    return (<Text fontSize=".83333rem" style={textStyle} >{props.children}</Text>);
};
