import React from 'react';
import { createUseStyles } from 'react-jss';

interface CenteredBoxProps {
    width?: string;
}

const centeredElement = {
    flex: "0 0 58.33333%",
    flexDirection: "column"
} as React.CSSProperties;


export const CenteredBox: React.FC<CenteredBoxProps> = ({ children, width = "50%" }) => {
    const useStyles = createUseStyles({
        wrapperStyle: {
            margin: "0 auto",
            borderRadius: ".375rem",
            width: `${width}`,
            boxShadow: "0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%)",
            boxSizing: "border-box",
            backgroundColor: "white"
        },
        '@media (max-width: 800px)':
        {
            wrapperStyle: {
                width: "95%",
            }
        },
        '@media (max-width: 1080px) and (min-width: 801px)':
        {
            wrapperStyle: {
                width: "70%"
            }
        }
    });
    const classes = useStyles();
    return (
        <div className={classes.wrapperStyle}>
            <div style={centeredElement}>
                {children}
            </div>
        </div >

    );
};