import React from 'react';

interface InputContainerProps {
    children: React.ReactNode;
}
const containerStyle = {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    width: "100%"
} as React.CSSProperties;

export const InputContainer: React.FC<InputContainerProps> = (props: InputContainerProps) => {
    return (<div style={containerStyle}>
        {props.children}
    </div>);
};