import { Checkbox, Link } from '@chakra-ui/react';
import React from 'react';
import { ErrorText } from './baseComponents/ErrorText';
import { RegistrationFormData } from '../types/FormData';
interface TermsAndConditionsCheckboxProps {
    onChange: (updatedFormData: Partial<RegistrationFormData>) => void;
    defaultIsChecked: boolean;
    errorText?: string;
}
const checkboxStyle = {
    alignSelf: "flex-start",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "15px"
} as React.CSSProperties;


export const TermsAndConditionsCheckbox: React.FC<TermsAndConditionsCheckboxProps> = (props: TermsAndConditionsCheckboxProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkbox = event.currentTarget;
        if (!checkbox?.checked) {
            props.onChange({ "acceptedConditions": false });
        } else {
            props.onChange({ "acceptedConditions": true });
        }
    };
    return (<>
        <Checkbox defaultChecked={props.defaultIsChecked} onChange={handleChange} style={checkboxStyle}>
            I accept the <Link color="teal.500" href="https://www.yetitext.com/terms-of-service" isExternal> terms and conditions</Link>
        </Checkbox>
        <ErrorText isVisible={Boolean(props.errorText)}>{props.errorText}</ErrorText>
    </>);
};